import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const MasterApprovalRBAC = {
  pageID: 'd32ee4351f14cbed389a8fe99b8f327a',
  elementID: {
    TableList: 'eaeaf0577b9f0bdbb32b6b288abdae94',
    ButtonSubmitAsApprover: 'bdf56491b57c2e0ee709fd3648545731',
    ButtonSubmitAsRequester: 'cb2a56ff9accabf577478ee6e45b948c',
    ButtonSubmitAsHR: '42d9600af4f4ad3b18fbe05ed5cee58c',
    ButtonSubmitAsSPV: 'a04efc23f11127b7bded14b1156f393f',
  },
} as const

export const useRBACMasterApprovalPage = createUseRBAC(MasterApprovalRBAC.elementID)

export type UseRBACMasterApprovalPageType = ReturnType<typeof useRBACMasterApprovalPage>
