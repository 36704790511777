import axios from 'axios'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API
const { Post, Put } = axiosInstanceApiGateway

export const postOrderPooling_OrderPooling = (data) =>
  axios.put(`${baseUrl}/api/packer/order/pooling/assign-driver`, data)

export const getDataReceiptPackerOrder = (order_id, params) => {
  if (params) {
    return axios.get(`${baseUrl}/api/order/${order_id}/receipt${params}`)
  } else {
    return axios.get(`${baseUrl}/api/order/${order_id}/receipt`)
  }
}

export const getDataProductSuper = (order_id) =>
  axios.get(`${baseUrl}/api/v2/order/${order_id}/products`)
export const postInitPackage = (orderId, data) => {
  if (data) {
    return axios.post(`${baseUrl}/api/order/${orderId}/receipt-init`, data)
  }
  return axios.post(`${baseUrl}/api/order/${orderId}/receipt-init`)
}

export const getDataReceiptPackerOrderSuper = (order_id, query) =>
  axios.get(`${baseUrl}/api/order/${order_id}/receipt${query}`)
export const getImageFile = (remote_URL) => axios.get(remote_URL, { responseType: 'blob' })

export const putChangeDriverOnDelivery = (order_id, data) =>
  axios.put(`${baseUrl}/api/packer/order/${order_id}/change-delivery-driver`, data)

export const getProductDetailExpiredSearchQrCode = ({ qr_code, quantity, orderID, type }) =>
  axios.put(`${baseUrl}/api/packer/order/${orderID}/scan-product`, {
    qr_code,
    quantity,
    product_type: type ? 3 : null,
  })
export const putScanProductAPI = (payload) => {
  const { orderID, ...rest } = payload
  return Put({ url: `/fulfillment/internal/v1/packing/orders/${orderID}/scan-item`, data: rest })
}
export const putScanPackagingAPI = (payload) => {
  const { orderID, ...rest } = payload
  return Put({
    url: `/fulfillment/internal/v1/packing/orders/${orderID}/scan-packaging`,
    data: rest,
  })
}
export const getKitchenItemByItemID = (payload) =>
  Post({
    url: `/fulfillment/internal/v1/packing/orders/${payload.order_id}/scan-kitchen`,
    data: payload,
  })
export const getProductDetailExpiredSearchQrCodeSuper = ({
  qr_code,
  quantity,
  orderID,
  type,
  location_id,
}) =>
  axios.put(`${baseUrl}/api/packer/super/order/${orderID}/scan-item`, {
    qr_code,
    quantity,
    product_type: type ? 3 : null,
    location_id: location_id,
  })

export const resetProductAPI = (payload) =>
  Post({
    url: `/fulfillment/internal/v1/packing/orders/${payload.order_id}/reset`,
    data: payload,
  })

export const putDeleteScannedProduct = (orderID, payload) =>
  axios.put(`${baseUrl}/api/packer/order/${orderID}/remove-scan-product`, payload)

export const putDeleteScannedProductSuper = (orderID, payload) =>
  axios.put(`${baseUrl}/api/packer/super/order/${orderID}/remove-scan-item`, payload)

export const getRemovableItems = (orderID) =>
  axios.get(`${baseUrl}/api/packer/order/${orderID}/removable-item`)
export const getRemovableItemsSuper = (orderID, locationId) =>
  axios.get(`${baseUrl}/api/packer/super/order/${orderID}/removable-item`, {
    params: { locationId: locationId },
  })

export const scanToRemoveItemAPI = (orderID, payload) =>
  Post({
    url: `/fulfillment/internal/v1/packing/orders/${orderID}/remove-vb-item`,
    data: payload,
  })
// Scan removable items
export const postScanToRemoveItem = (orderID, payload) =>
  axios.post(`${baseUrl}/api/packer/order/${orderID}/scan-product/remove`, payload)
// Scan removable items super
export const postScanToRemoveItemSuper = (orderID, payload) =>
  axios.post(`${baseUrl}/api/packer/super/order/${orderID}/scan-item/remove`, payload)

// Astro relabel
export const getRelabelItems = (id) => axios.get(`${baseUrl}/api/packer/order/${id}/item-label`)

// Notify partial fulfill for external order (i.e GrabMart, etc)
export const notifyPartialFulfill = (orderId) =>
  axios.post(`${baseUrl}/api/packer/order/${orderId}/notify-partial-fulfill`)
