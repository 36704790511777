import { AUTH_ASTRO_GO_MANAGER, AUTH_ASTRO_GO_STAFF } from 'constant/roles'
import {
  AUTH_CENTRAL_SUPERVISOR,
  AUTH_CS_CAPTAIN,
  AUTH_CAPTAIN,
  AUTH_CUSTOMER_SERVICE,
  AUTH_ADMIN,
  AUTH_QUALITY_ENGINEERING,
  AUTH_PRODUCT_MANAGER,
  AUTH_HO_CS_LEADER,
  AUTH_HO_ASTRO_KITCHEN_MANAGER,
  AUTH_HO_ASTRO_KITCHEN_STAFF,
  AUTH_HO_LP,
  AUTH_HO_FINANCE,
  AUTH_CS_AGENT,
  AUTH_HUB_AREA_MANAGER,
  AUTH_HO_CAMPAIGN,
  AUTH_HO_MARKETING,
  AUTH_HO_PARTNERSHIP,
  AUTH_WH_MANAGER_VIEWONLY,
  AUTH_HUB_INV_MANAGER,
  AUTH_HUB_INV_MANAGER_VIEWONLY,
  AUTH_HUB_INV_SPV,
  AUTH_HUB_INV_STAFF,
  AUTH_HUB_INBOUND_STAFF,
  AUTH_QA_MANAGER,
  AUTH_QA_SUPERVISOR,
  AUTH_QA_STAFF,
  AUTH_HUB_AREA_MANAGER_VIEWONLY,
  AUTH_SUPER_INBOUND_STAFF,
  AUTH_INTERNAL_SALES,
  AUTH_HO_PRODUCT_MANAGER,
  AUTH_HO_QUALITY_ENGINEERING,
  AUTH_ASTRO_GO_SPV,
  AUTH_ASTRO_GO_SHIFT_LEAD,
} from 'middleware/privateRoute'

const allOrder = {
  addOrder: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CS_CAPTAIN,

    //HUB
    AUTH_CAPTAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_INTERNAL_SALES,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  finish: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CS_CAPTAIN,

    //HUB
    AUTH_CAPTAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_INTERNAL_SALES,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  cancel: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CS_CAPTAIN,

    //HUB
    AUTH_CAPTAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CS_LEADER,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_INTERNAL_SALES,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  editAddress: [
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  skipVerifyLocation: [
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_INTERNAL_SALES,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  callInstantCourier: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CS_CAPTAIN,

    //HUB
    AUTH_CUSTOMER_SERVICE,
    AUTH_CAPTAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_INTERNAL_SALES,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  sudoCancel: [
    AUTH_CS_CAPTAIN,
    AUTH_ADMIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CS_LEADER,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  pooling: [
    AUTH_CS_CAPTAIN,
    AUTH_ADMIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_CS_AGENT,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  skipVerifyPackage: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CS_CAPTAIN,

    //HUB
    AUTH_CAPTAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_CS_AGENT,
    AUTH_INTERNAL_SALES,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  viewOnly: [
    AUTH_HO_LP,
    AUTH_HO_FINANCE,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_MARKETING,
    AUTH_HO_PARTNERSHIP,
    AUTH_WH_MANAGER_VIEWONLY,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,
    AUTH_HUB_INBOUND_STAFF,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_SUPER_INBOUND_STAFF,
    AUTH_ASTRO_GO_MANAGER,
    AUTH_ASTRO_GO_SPV,
    AUTH_ASTRO_GO_SHIFT_LEAD,
    AUTH_ASTRO_GO_STAFF,
  ],
  /* Order detail */
  identifiableData: [
    AUTH_CS_AGENT,
    AUTH_HO_CS_LEADER,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_HUB_AREA_MANAGER,
  ],
}

export default allOrder
