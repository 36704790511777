import AxiosInstance from 'config/apiServiceApiGateway'
import { LoadingDockType } from './loadingDock'

export type LoadingDockTimeSlotType = {
  id: number
  locationId: number
  loadingDockId: number
  name: string
  startAt: string
  endAt: string
  manpower: number
  manpowerProductivity: number
  timeSlotProductivity: number
  isActive: boolean
  createdBy: string
  remarks: string
}

type TimeSlotType = Pick<LoadingDockType, 'id' | 'type' | 'name' | 'location'> & {
  loadingDockTimeSlot: LoadingDockTimeSlotType[]
}

export type TimeSlotParamType = {
  loadingDockId: number | null
}

export type TimeSlotRequestParamType = Partial<TimeSlotParamType>

export interface GetTimeSlotInterface {
  data: TimeSlotType
  message: string
}

export type UpdateTimeSlotDataType = {
  manpower: number
  manpowerProductivity: number
  isActive: boolean
  remarks: string
  executedBy: string
}

export type UpdateTimeSlotStatusDataType = { is_active: boolean; executed_by: string }

export const getTimeSlotListApi = (id: number, params?: { active?: boolean }) =>
  AxiosInstance.Get<GetTimeSlotInterface, true>({
    url: '/staff-management/internal/:version/loading-dock/:id/ldts',
    version: 'v1',
    params,
    urlParams: { id },
    convertResponse: true,
  })

export const putTimeSlot = (id: number, data: UpdateTimeSlotDataType) =>
  AxiosInstance.Put({
    url: '/staff-management/internal/:version/loading-dock-time-slot/:id',
    version: 'v1',
    data,
    urlParams: { id },
    convertRequest: true,
  })

export const putTimeSlotStatus = (id: number, data: UpdateTimeSlotStatusDataType) =>
  AxiosInstance.Put({
    url: '/staff-management/internal/:version/loading-dock-time-slot/:id/status',
    version: 'v1',
    data,
    urlParams: { id },
  })
