import { Box, Button, ButtonPropsType, Icon, Menu, Typography } from '@astro-ui/components'
import { MenuItem } from '@mui/material'
import { useState } from 'react'

interface MenuAturPropsInterface {
  label?: string
  className?: string
  options: {
    name: string
    value: unknown
    disabled?: boolean
    suffix?: React.ReactElement
  }[] // Array of options
  active?: boolean
  sxButton?: ButtonPropsType['sx']
  onSelect?: (opts: MenuAturPropsInterface['options'][number]) => void
}

const MenuAtur = ({
  onSelect,
  options,
  label,
  className,
  active,
  sxButton,
}: MenuAturPropsInterface) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (opts: MenuAturPropsInterface['options'][number]) => {
    if (onSelect) {
      onSelect(opts)
    }
    handleClose()
  }

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleOpen}
        endIcon={<Icon icon="dropdown" size={18} />}
        sx={{
          backgroundColor: active ? '#246EE5' : 'transparent',
          color: active ? 'white' : '#778092',
          border: active ? '1px solid #246EE5' : '1px solid #778092',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ':hover': {
            border: '1px solid black',
            backgroundColor: active ? '#246EE5' : 'transparent',
            color: active ? 'white' : '#778092',
          },
          ...sxButton,
        }}
      >
        <Typography variant="body2" fontWeight="bold" color="inherit">
          {label}
        </Typography>
      </Button>
      <Menu
        className={`${className} menu-action`}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        // eslint-disable-next-line @typescript-eslint/naming-convention
        sx={{ '& .MuiPopover-paper': { maxHeight: '500px !important' } }}
      >
        {options.length ? (
          options.map((item) => (
            <MenuItem
              key={item.name}
              className="menu-action-list"
              disabled={item.disabled}
              onClick={() => handleSelect(item)}
              sx={{ padding: '8px 16px' }}
            >
              <Typography variant="body2" color="#778092" mr="8px">
                {item.name}
              </Typography>
              {item?.suffix}
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            <Typography variant="body2" color="#778092">
              No Data
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}

export default MenuAtur
