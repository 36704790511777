import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const SLICE_NAME = 'flagManagement'
export type GenericErrorType = {
  status: boolean
  message?: string
  code: number
}
export type FeatureFlagPaginationType = {
  sort: string
  direction: 'ASC' | 'DESC'
  page_size: number
  page_index?: number
  total_element?: number
  number_of_elements?: number
  total_pages: number
}

export type FeatureFlagType = {
  hub_feature_id: number
  feature_key: string
  active: boolean
  device_id: number
  device_name: string
  updated_at?: Nullable<number> | Nullable<string>
}

export type FeatureFlagResponseType = {
  hub_features: Array<FeatureFlagType>
  pagination: FeatureFlagPaginationType
  error: GenericErrorType
}

export type LocationContentType = {
  location_id: number
  location_name: string
  location_type: string
  updated_at: number
  active: boolean
}

export type LocationSortType = {
  unsorted: boolean
  sorted: boolean
  empty: boolean
}

export type LocationPaginationType = {
  sort: LocationSortType
  page_size: number
  page_index: number
  number_of_elements: number
  total_element: number
  total_pages: number
}

export type LocationResponseType = {
  locations: Array<LocationContentType>
  pagination: LocationPaginationType
  total_element: number
  total_pages: number
  last: boolean
  first: boolean
  sort: LocationSortType
  number_of_elements: number
  size: number
  number: number
  empty: boolean
}
export interface FlagManagementStateInterface {
  featureFlags: FeatureFlagResponseType
  locations: LocationResponseType
  isLoading: boolean
  sortActive: boolean
  unsortedLocations: LocationResponseType
}

const initialState: FlagManagementStateInterface = {
  featureFlags: {} as FeatureFlagResponseType,
  locations: {} as LocationResponseType,
  isLoading: false,
  sortActive: false,
  unsortedLocations: {} as LocationResponseType,
}

const flagManagementSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setFeatureFlags: (state, action) => {
      if (action.payload.pagination.pageIndex >= 1) {
        if (state.featureFlags.hub_features.length === action.payload.pagination.totalElement) {
          return
        }
        state.featureFlags = {
          ...action.payload,
          data: [...state.featureFlags.hub_features, ...action.payload.data],
        }
        return
      }
      state.featureFlags = action.payload
    },
    setLocations: (state, action: PayloadAction<LocationResponseType>) => {
      if (action.payload.pagination.page_index >= 1) {
        if (state.locations?.locations?.length === action.payload.pagination.total_element) {
          return
        }
        state.locations = {
          ...action.payload,
          locations: [...state.locations.locations, ...action.payload.locations],
        }
        return
      }
      state.locations = action.payload
    },
    setSortActive: (state, action: PayloadAction<boolean>) => {
      state.sortActive = action.payload
    },
    reset: () => initialState,
  },
})

export const { reset, setFeatureFlags, setLocations, setLoading, setSortActive } =
  flagManagementSlice.actions
export default flagManagementSlice.reducer
