import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

type NamedRoutesType = 'PricingEngineRouter'
type PathListsType = '/dashboard/pricing-engine/:page'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const PRIVATE_ROUTE_TYPE = 'PRIVATE'

const PricingEnginePages = lazy(() => import('../pages/page'))
const PricingEngineNewRequestPage = lazy(() => import('../pages/NewRequest'))
const PricingEngineReviewPage = lazy(() => import('../pages/Review'))
const PricingEngineEditPage = lazy(() => import('../pages/Edit'))
const PricingEngineNewSkuListPage = lazy(() => import('../pages/NewSKUList'))
const PricingEngineReviewSKUPage = lazy(() => import('../pages/ReviewSKU'))

export const pricingEngineDefaultPath = '/dashboard/pricing-engine/products'

export const PricingEngineProductRouter: RouterInstanceType = {
  name: 'PricingEngineRouter',
  Component: PricingEnginePages,
  path: '/dashboard/pricing-engine/:page',
  routeType: PRIVATE_ROUTE_TYPE,
  allowedRoles: [],
  isDashboard: true,
  multiplePageID: {
    keySegment: 'page',
    pageIDmatchWithKeySegments: {
      products: 'ac7da56f50a8a58dbb8dca6c4d907c04',
      requests: 'e86cf176d2b8164182ae170a05987a62',
      history: '868665729be98555c8b421f646c1ee54',
    },
  },
}

export const PricingEngineNewRequestRouter: RouterFeatureInterface<
  'PricingEngineNewRequestRouter',
  '/dashboard/pricing-engine/requests/new'
> = {
  name: 'PricingEngineNewRequestRouter',
  Component: PricingEngineNewRequestPage,
  path: '/dashboard/pricing-engine/requests/new',
  routeType: PRIVATE_ROUTE_TYPE,
  allowedRoles: [],
  isDashboard: true,
  rbacPageID: '210508f016a12f249ac8f0acdeeb58d7',
}

export const PricingEngineReviewRouter: RouterFeatureInterface<
  'PricingEngineReviewRouter',
  '/dashboard/pricing-engine/requests/review'
> = {
  name: 'PricingEngineReviewRouter',
  Component: PricingEngineReviewPage,
  path: '/dashboard/pricing-engine/requests/review',
  routeType: PRIVATE_ROUTE_TYPE,
  allowedRoles: [],
  isDashboard: true,
  rbacPageID: '75608f65105acb3edd81086d00c9b206',
}

export const PricingEngineEditRouter: RouterFeatureInterface<
  'PricingEngineEditRouter',
  '/dashboard/pricing-engine/products/edit'
> = {
  name: 'PricingEngineEditRouter',
  Component: PricingEngineEditPage,
  path: '/dashboard/pricing-engine/products/edit',
  routeType: PRIVATE_ROUTE_TYPE,
  allowedRoles: [],
  isDashboard: true,
  rbacPageID: '31770803454172340375ac82ca142997',
}

export const PricingEngineEditRequestRouter: RouterFeatureInterface<
  'PricingEngineEditRequestRouter',
  '/dashboard/pricing-engine/requests/edit/:requestId'
> = {
  name: 'PricingEngineEditRequestRouter',
  Component: PricingEngineNewRequestPage, // intended to use the same component for new and edit request
  path: '/dashboard/pricing-engine/requests/edit/:requestId',
  routeType: PRIVATE_ROUTE_TYPE,
  allowedRoles: [], // intended to use the same component for new and edit request
  isDashboard: true,
  rbacPageID: 'cd5d979aa2998a97d0e88ee043fedfc7',
}

export const PricingEngineNewSKUListRouter: RouterFeatureInterface<
  'PricingEngineNewSKUListRouter',
  '/dashboard/pricing-engine/requests/new-sku'
> = {
  name: 'PricingEngineNewSKUListRouter',
  path: '/dashboard/pricing-engine/requests/new-sku',
  Component: PricingEngineNewSkuListPage,
  routeType: PRIVATE_ROUTE_TYPE,
  allowedRoles: [],
  isDashboard: true,
}

export const PricingEngineReviewSKUPageRouter: RouterFeatureInterface<
  'PricingEngineNewSKUListRouter',
  '/dashboard/pricing-engine/requests/new-sku/review'
> = {
  name: 'PricingEngineNewSKUListRouter',
  path: '/dashboard/pricing-engine/requests/new-sku/review',
  Component: PricingEngineReviewSKUPage,
  routeType: PRIVATE_ROUTE_TYPE,
  allowedRoles: [],
  isDashboard: true,
}

const PricingEngineRouter = [
  PricingEngineProductRouter,
  PricingEngineNewRequestRouter,
  PricingEngineReviewRouter,
  PricingEngineEditRouter,
  PricingEngineEditRequestRouter,
  PricingEngineNewSKUListRouter,
  PricingEngineReviewSKUPageRouter,
]

export const PricingEngineRouterDetail = generateRouterDetail(PricingEngineRouter)

export default PricingEngineRouter
