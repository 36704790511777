import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import type { CompetitorListSchemaType } from 'features/PricingEngine/pages/ReviewSKU/schema/competitors'

export type GeneralResponseType = {
  message: string
  error: {
    message: string
    code: number
  }
}

export type ProductRegistrationStatusType =
  | 'IMS_SUBMITTED'
  | 'IMS_REJECTED'
  | 'IMS_APPROVED'
  | 'COMPETITOR_DRAFTED'
  | 'COMPETITOR_SUBMITTED'
  | 'RSP_SUBMITTED'

export type GetProductRegistrationParamsType = {
  page_size?: number
  cursor?: string
  order_by?: string
  order_direction?: 'ASC' | 'DESC'
  sku_number?: string
  product_name?: string
  statuses?: ProductRegistrationStatusType
  ids?: string
}

export type ProductRegistrationItemType = {
  id: number
  barcode: string
  product_name: string
  business_unit: string
  business_tagging_name: string
  category_l1_name: string
  category_l2_name: string
  product_type_name: string
  uom: string
  cogs: string
  srp: string
  created_at: string
  status: string
}

export type ProductRegistrationResponseType = {
  data: ProductRegistrationItemType[]
  pagination: {
    prev_cursor: string
    next_cursor: string
  }
  error: {
    message: string
    code: number
  }
}

export type ProductRegistrationDetailType = {
  id: string
  product_name: string
  uom: string
  barcode: string
  status: string
  created_at: string
  business_unit: string
  business_tagging_name: string
  product_type_name: string
  category_l1_name: string
  category_l2_name: string
  primary_uom_qty: string
  primary_uom_type_name: string
  secondary_uom_qty: string
  secondary_uom_type_name: string
  cogs: string
  srp: string
  het_min: string
  het_max: string
  het_doc_agreement: string
  competitor_price: string
  competitor_link: string
  reject_reason_text: string
  rsp: string
  margin: string
}

export type ProductRegistrationDetailResponseType = {
  data: ProductRegistrationDetailType
  error: {
    message: string
    code: number
  }
}

export type RejectReasonItemType = {
  id: number
  reason: string
  attributes: string[]
}

export type RejectReasonsResponseType = {
  data: RejectReasonItemType[]
  error: {
    message: string
    code: number
  }
}

export type CompetitorOptionType = {
  id: string
  name: string
  link_required: string
  product_id_required: string
  sku_required: string
}

export type CompetitorOptionsResponseType = {
  data: CompetitorOptionType[]
  error: {
    message: string
    code: number
  }
}

export type CompetitorListItemType = {
  competitor_id: number
  price: string
  uom_type: string
  uom_qty: number
  link: string
  product_id: string
  sku: string
}

export type CompetitorListResponseType = {
  competitors: CompetitorListItemType[]
  error: {
    message: string
    code: number
  }
}

export type UomOptionType = {
  id: string
  description: string
}

export type UomOptionsResponseType = {
  data: UomOptionType[]
  error: {
    message: string
    code: number
  }
}

export type CompetitorDraftResponseType = {
  message: string
  detail_error: {
    message: string
    competitors: {
      [key: string]: {
        message: string
        fields: {
          price: string
        }
      }
    }
  }
}

export type CalculateRSPParamsType = {
  rsp?: string
  margin?: string
}

export type CalculateRSPResponseType = {
  data: {
    rsp: string
    margin: string
  }
}

export type SubmitRSPParamsType = {
  rsp: string
}

export const getProductRegistration = (params?: GetProductRegistrationParamsType) =>
  axiosInstanceApiGateway.Get<ProductRegistrationResponseType, true>({
    url: '/pricing-engine/internal/v1/product-registration',
    params,
  })

export const getProductRegistrationById = (id: string | number) =>
  axiosInstanceApiGateway.Get<ProductRegistrationDetailResponseType, true>({
    url: `/pricing-engine/internal/v1/product-registration/${id}`,
  })

export const rejectProductRegistration = (reasonIds: string[], skuId: number) =>
  axiosInstanceApiGateway.Post<GeneralResponseType, true>({
    url: `/pricing-engine/internal/v1/product-registration/${skuId}/ims/reject`,
    data: {
      reason_ids: reasonIds,
    },
  })

export const approveProductRegistration = (skuId: number) =>
  axiosInstanceApiGateway.Post<GeneralResponseType, true>({
    url: `/pricing-engine/internal/v1/product-registration/${skuId}/ims/approve`,
  })

export const getRejectReasons = () =>
  axiosInstanceApiGateway.Get<RejectReasonsResponseType, true>({
    url: '/pricing-engine/internal/v1/reasons/reject_ims',
  })

export const getCompetitorList = (productId: string) =>
  axiosInstanceApiGateway.Get<CompetitorListResponseType, true>({
    url: `/pricing-engine/internal/v1/product-registration/${productId}/competitor`,
  })

export const getCompetitorOptions = () =>
  axiosInstanceApiGateway.Get<CompetitorOptionsResponseType, true>({
    url: '/pricing-engine/internal/v1/competitors',
  })

export const getUomOptions = () =>
  axiosInstanceApiGateway.Get<UomOptionsResponseType, true>({
    url: '/pricing-engine/internal/v1/unit-of-measurements',
  })

export const postCompetitorDraft = (productId: string, data: CompetitorListSchemaType) =>
  axiosInstanceApiGateway.Put<CompetitorDraftResponseType, true>({
    url: `/pricing-engine/internal/v1/product-registration/${productId}/competitor/draft`,
    data,
  })

export const postCompetitorSubmit = (productId: string) =>
  axiosInstanceApiGateway.Put<GeneralResponseType, true>({
    url: `/pricing-engine/internal/v1/product-registration/${productId}/competitor/submit`,
  })

export const calculateRSP = (productId: string, data: CalculateRSPParamsType) =>
  axiosInstanceApiGateway.Post<CalculateRSPResponseType, true>({
    url: `/pricing-engine/internal/v1/product-registration/${productId}/rsp/calculate`,
    data,
  })

export const submitRSP = (productId: string, data: SubmitRSPParamsType) =>
  axiosInstanceApiGateway.Post<GeneralResponseType, true>({
    url: `/pricing-engine/internal/v1/product-registration/${productId}/rsp/submit`,
    data,
  })
