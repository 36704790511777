import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { tidyNumber } from 'utils/number'
import { toastFailed, toastSuccess } from 'utils/toast'
import { getLocationDetail } from 'features/Enterprise/CapacityHandler/services/inboundSchedule'
import {
  deletePoNumber,
  getAvailableTimeSlot,
  getInboundScheduleDetail,
  getPoNumber,
  getSlotList,
  getVendors,
  PoNumberType,
  postInboundSchedule,
  putInboundSchedule,
  getAuditTrails,
  getReasons,
  type GetAvailableTimeSlotParamsType,
  type GetPoNumberParamsType,
  type PostInboundScheduleDataType,
  type PutInboundScheduleDataType,
  type GetAuditTrailsDataType,
  type GetSlotListType,
  type GetReasonType,
} from 'features/Enterprise/CapacityHandler/services/inboundScheduleAdd'

import {
  SLICE_NAME,
  setLocation,
  setVendorList,
  setSlotList,
  setAvailableTimeSlot,
  setPoNumberList,
  setSelectedPoNumber,
  setSelectedPoNumberToDelete,
  setInboundScheduleDetail,
  setLoadingFetchDetail,
  setScheduleDate,
  setVendor,
  setSlot,
  setTimeSlot,
  setAuditTrailsData,
  setReasons,
} from './inboundScheduleAddSlice'

export const fetchGetLocationDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchGetLocationDetail`,
  async (id: string, { dispatch }) => {
    try {
      const { data } = await getLocationDetail(id)
      dispatch(setLocation(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchVendors = createAsyncThunk(
  `${SLICE_NAME}/fetchVendors`,
  async (companyName: string, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getVendors(companyName)

      dispatch(setVendorList(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchSlotList = createAsyncThunk(
  `${SLICE_NAME}/fetchSlotList`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getSlotList()
      dispatch(setSlotList(data as unknown as GetSlotListType['data']))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchAvailableTimeSlot = createAsyncThunk(
  `${SLICE_NAME}/fetchAvailableTimeSlot`,
  async (params: GetAvailableTimeSlotParamsType, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getAvailableTimeSlot(params)

      dispatch(setAvailableTimeSlot(data))
      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPoNumberList = createAsyncThunk(
  `${SLICE_NAME}/fetchPoNumberList`,
  async (params: GetPoNumberParamsType, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getPoNumber(params)

      dispatch(setPoNumberList(data))
      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const saveInboundSchedule = createAsyncThunk(
  `${SLICE_NAME}/saveInboundSchedule`,
  async (data: PostInboundScheduleDataType, { rejectWithValue }) => {
    try {
      const res = await postInboundSchedule({ ...data })
      toastSuccess('Jadwal inbound PO berhasil dibuat.')
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const removePoNumber = createAsyncThunk(
  `${SLICE_NAME}/removePoNumber`,
  async (
    { scheduleId, selectedReason }: { scheduleId: string; selectedReason: string },
    { getState, rejectWithValue, dispatch },
  ) => {
    const {
      inboundScheduleAdd: { selectedPoNumberToDelete, selectedPoNumber, poNumberList },
      auth: {
        userData: { full_name, id },
      },
    } = getState() as StoreStateType

    try {
      const response = await deletePoNumber(
        scheduleId,
        selectedPoNumberToDelete?.purchase_order_id as number,
        {
          executed_by: full_name,
          user_id: id,
          reason: selectedReason,
        },
      )
      toastSuccess(`PO ${selectedPoNumberToDelete?.purchase_order_id} berhasil dihapus.`)
      dispatch(
        setSelectedPoNumber(
          selectedPoNumber.filter(
            (po) => po.purchase_order_id !== selectedPoNumberToDelete?.purchase_order_id,
          ),
        ),
      )

      const updatedPoNumberList = [
        ...poNumberList.map((po) => ({
          purchaseOrderId: po.purchase_order_id,
          purchaseOrderNumber: po.purchase_order_number,
          totalPcs: po.total_pcs,
          reason: '',
        })),
        {
          purchaseOrderId: selectedPoNumberToDelete?.purchase_order_id,
          purchaseOrderNumber: selectedPoNumberToDelete?.purchase_order_number,
          totalPcs: selectedPoNumberToDelete?.total_pcs,
          reason: '',
        },
      ] as PoNumberType[]
      dispatch(setPoNumberList(updatedPoNumberList))
      dispatch(setSelectedPoNumberToDelete(null))

      return response.data
    } catch (error) {
      toastFailed(`PO ${selectedPoNumberToDelete?.purchase_order_number} gagal dihapus.`)
      return rejectWithValue(error)
    }
  },
)

export const updateInboundSchedule = createAsyncThunk(
  `${SLICE_NAME}/updateInboundSchedule`,
  async (params: PutInboundScheduleDataType & { scheduleId: string }, { rejectWithValue }) => {
    const { scheduleId, ...updateData } = params
    try {
      const res = await putInboundSchedule(scheduleId, updateData)
      toastSuccess('Jadwal inbound PO berhasil diubah.')

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchInboundScheduleDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchInboundScheduleDetail`,
  async (scheduleId: string, { dispatch, getState }) => {
    dispatch(setLoadingFetchDetail(true))

    const {
      inboundScheduleAdd: { pageType },
    } = getState() as StoreStateType

    try {
      const {
        data: { data },
      } = await getInboundScheduleDetail(scheduleId)
      dispatch(setInboundScheduleDetail(data))
      dispatch(fetchGetLocationDetail(`${data.time_slot.location_id}`))
      dispatch(setScheduleDate(new Date(data.date)))
      dispatch(setSlot({ slot: data.slot, label: `${data.slot} Slot` }))
      dispatch(
        setTimeSlot({
          ...data.time_slot,
          label: `${data.time_slot.name} (${data.time_slot.start_at} - ${
            data.time_slot.end_at
          }) ${tidyNumber(data.time_slot.timeslot_productivity)} PCS`,
        }),
      )
      dispatch(
        fetchAvailableTimeSlot({
          date: data.date,
          slot: data.slot,
          loading_dock_id: data.time_slot.loading_dock_id,
        }),
      )
      dispatch(setSelectedPoNumber(data.purchase_order.map((po) => ({ ...po, hasStored: true }))))

      if (data.vendor) {
        dispatch(setVendor({ id: data.vendor.vendor_id, companyName: data.vendor.vendor_name }))
      }

      if (pageType === 'UPDATE') {
        const poNumberList = await dispatch(
          fetchPoNumberList({
            destinationId: data.time_slot.location_id,
          }),
        ).unwrap()
        const filteredPoNumberList = poNumberList.filter(
          (item) =>
            !data.purchase_order.find((po) => item.purchaseOrderId === po.purchase_order_id),
        )
        dispatch(setPoNumberList(filteredPoNumberList))
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingFetchDetail(false))
    }
  },
)

export const fetchAuditTrail = createAsyncThunk(
  `${SLICE_NAME}/fetchAuditTrail`,
  async (
    { scheduleId, page_index = 1 }: { scheduleId: string; page_index?: number },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingFetchDetail(true))
      const {
        data: { data, pagination },
      } = await getAuditTrails(scheduleId, page_index)
      dispatch(setAuditTrailsData({ data, pagination } as unknown as GetAuditTrailsDataType))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingFetchDetail(false))
    }
  },
)

export const fetchReasons = createAsyncThunk(
  `${SLICE_NAME}/fetchReasons`,
  async (type: GetReasonType, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getReasons(type)
      dispatch(setReasons(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)
