import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getPoolDataAPI,
  tagPoolDataAPI,
  unTagPoolDataAPI,
  fetchUnpoolReasonsAPI,
} from 'utils/apiList/orderPool'

const initialState = {
  pools: [],
  isLoading: false,
  mainPoolData: [],
}

const orderPooling = createSlice({
  name: 'orderPooling',
  initialState,
  reducers: {
    resetPool: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setPools: (state, action) => {
      const { pools } = state
      const index = pools.findIndex((each) => each.order_invoice === action.payload.order_invoice)
      if (index == -1) {
        state.pools.push(action.payload)
      } else {
        state.pools = pools.filter((each) => each.order_invoice !== action.payload.order_invoice)
      }
    },
    setMainPoolData: (state, action) => {
      state.mainPoolData = action.payload
    },
  },
})

export const { resetPool, setLoading, setPools, setMainPoolData } = orderPooling.actions

export const fetchPoolData = createAsyncThunk(
  'orderPooling/fetchPoolData',
  async (payload, { dispatch, getState }) => {
    dispatch(setLoading(true))
    try {
      const response = await getPoolDataAPI(payload)
      const data = response.data.data
      const reformatPoolNumber = [...data].map((poolOrderResp) => {
        if (poolOrderResp.pool_number !== 0) {
          return {
            ...poolOrderResp,
            formatted_pool_number: poolOrderResp.orders[0].formatted_pool_number,
          }
        }
        return {
          ...poolOrderResp,
          formatted_pool_number: poolOrderResp.pool_number.toString(),
        }
      })
      dispatch(setMainPoolData(reformatPoolNumber))
      return data
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)
/**
 * @typedef {Object} TagPoolDataPayload
 * @property {string} order_invoice - The order invoice number
 * @property {string} pool_id - The pool ID
 */

/**
 * @param {TagPoolDataPayload} payload
 */
export const tagPoolData = createAsyncThunk(
  'orderPooling/tagPoolData',
  async (payload, { dispatch }) => {
    try {
      const response = await tagPoolDataAPI(payload)
      return response
    } catch (error) {
      callErrorMsg(error)
      return error
    }
  },
)

export const unTagPoolData = createAsyncThunk(
  'orderPooling/unTagPoolData',
  async (payload, { dispatch }) => {
    try {
      const response = await unTagPoolDataAPI(payload)
      return response
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const getUnpoolReasons = createAsyncThunk(
  'orderPooling/getUnpoolReasons',
  async (payload, { dispatch }) => {
    try {
      const response = await fetchUnpoolReasonsAPI()
      return response
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export default orderPooling.reducer
